//------------------------------------------------------------------------------
// HELPERS

.default-font {
  font-family: $font-family;
  font-size: rem(13);
  font-weight: $font-weight-normal;
  line-height: $line-height;

  @media (min-width: $break-sm-rem) {
    font-size: calc(#{rem(13)} + 0.125 * (100vw - #{$break-sm-rem}) / (#{rem-unitless($break-xl)} - #{rem-unitless($break-sm)}));
  }

  @media (min-width: $break-xl-rem) {
    font-size: rem(15);
  }
}

.section-heading-font {
  font-family: $font-family-heading;
  font-size: rem(14);
  font-weight: $font-weight-normal;
  letter-spacing: 0.05em;

  @media (min-width: $break-sm-rem) {
    font-size: calc(#{rem(14)} + 0.3125 * (100vw - #{$break-sm-rem}) / (#{rem-unitless($break-xl)} - #{rem-unitless($break-sm)}));
  }

  @media (min-width: $break-xl-rem) {
    font-size: rem(19);
  }
}

.dialog-heading-font {
  font-family: $font-family-heading;
  font-size: rem(14);
  font-weight: $font-weight-normal;
  letter-spacing: 0.125em;
  text-transform: uppercase;

  @media (min-width: $break-sm-rem) {
    font-size: calc(#{rem(14)} + 0.125 * (100vw - #{$break-sm-rem}) / (#{rem-unitless($break-xl)} - #{rem-unitless($break-sm)}));
  }

  @media (min-width: $break-xl-rem) {
    font-size: rem(16);
  }
}

.special-font {
  font-family: $font-family-special;
  font-size: rem(13);
  font-weight: $font-weight-normal;
  line-height: 1.2;

  @media (min-width: $break-sm-rem) {
    font-size: calc(#{rem(13)} + 0.0625 * (100vw - #{$break-sm-rem}) / (#{rem-unitless($break-xl)} - #{rem-unitless($break-sm)}));
  }

  @media (min-width: $break-xl-rem) {
    font-size: rem(14);
  }
}

.h2 {
  font-family: $font-family-heading;
  font-size: rem(14);
  font-weight: $font-weight-bold;
  line-height: 1.3;

  @media (min-width: $break-sm-rem) {
    font-size: calc(#{rem(14)} + 0.125 * (100vw - #{$break-sm-rem}) / (#{rem-unitless($break-xl)} - #{rem-unitless($break-sm)}));
  }

  @media (min-width: $break-xl-rem) {
    font-size: rem(16);
  }
}


//------------------------------------------------------------------------------
// HTML / BODY

html {
  text-size-adjust: 100%;
}

body {
  @extend .default-font;
  color: $text-color;
  font-feature-settings: "liga", "kern";
  font-kerning: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: geometricPrecision;
}


//------------------------------------------------------------------------------
// HEADING

h1,
.h1 {
  font-family: $font-family-heading;
  font-size: rem(15);
  font-weight: $font-weight-normal;
  letter-spacing: 0.15em;
  margin: 0;
  text-transform: uppercase;
  user-select: none;

  @media (min-width: $break-sm-rem) {
    font-size: calc(#{rem(15)} + 0.5625 * (100vw - #{$break-sm-rem}) / (#{rem-unitless($break-xl)} - #{rem-unitless($break-sm)}));
  }

  @media (min-width: $break-xl-rem) {
    font-size: rem(24);
  }
}

h2 {
  @extend .h2;
  margin-bottom: rem(7);
  margin-top: rem(16);
}

h3 {
  font-family: $font-family-heading;
  font-size: rem(13);
  font-weight: $font-weight-bold;
  margin-bottom: rem(7);
  margin-top: rem(16);

  @media (min-width: $break-sm-rem) {
    font-size: calc(#{rem(13)} + 0.125 * (100vw - #{$break-sm-rem}) / (#{rem-unitless($break-xl)} - #{rem-unitless($break-sm)}));
  }

  @media (min-width: $break-xl-rem) {
    font-size: rem(15);
  }
}

h4 {
  @extend .default-font;
  font-weight: $font-weight-bold;
  margin: 0;
}

h5,
h6 {
  margin: 0;
}


//------------------------------------------------------------------------------
// ABSATZ

p {
  margin-bottom: rem(10);
  margin-top: 0;
}


//------------------------------------------------------------------------------
// SMALL

small {
  font-size: 75%;
}
