//------------------------------------------------------------------------------
// FORM

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

label {
  cursor: pointer;
  user-select: none;
}

input {
  border-radius: 0;
  font-family: inherit;

  &[type="date"],
  &[type="datetime-local"] {
    appearance: none;
    cursor: text;
    min-width: 100%; // iOS-Bugfix
  }

  &[type="number"] {
    appearance: textfield;
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 rem(1000) $text-color inset;
    -webkit-text-fill-color: $text-color-dark;
  }

  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
    opacity: 0;
  }

  &::-webkit-clear-button,
  &::-ms-clear {
    display: none;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  &::-webkit-contacts-auto-fill-button,
  &::-webkit-credentials-auto-fill-button {
    pointer-events: none;
    visibility: hidden;
  }

  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
}

select {
  cursor: pointer;
  font-family: inherit;

  &::-ms-expand {
    display: none;
  }

  &:focus {
    &::-ms-value {
      background: transparent; // Disable option background color
    }
  }
}

button {
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  overflow: hidden;
  user-select: none;
}


//------------------------------------------------------------------------------
// TABLE

table {
  border-collapse: collapse;
}

th,
td {
  padding: 0;
}


//------------------------------------------------------------------------------
// IMAGE

svg {
  height: 100%;
  transform: scale(1); // Firefox: Subpixel-Bugfix
  width: 100%;
}
