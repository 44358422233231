//------------------------------------------------------------------------------
// IMPORTS

@import "func";
@import "variables";
@import "normalize";
@import "type";
@import "grid";


//------------------------------------------------------------------------------
// DEFAULTS

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: fade($brand-color, 10%);
}


//------------------------------------------------------------------------------
// HELPERS

.loading-spinner {
  animation: loading-spinner 1s linear infinite;
  border: rem(3) solid $text-color;
  border-radius: 50%;
  border-right-color: transparentize($text-color, 0.75);
  border-top-color: transparentize($text-color, 0.75);
  display: block;
  height: rem(20);
  opacity: 1;
  visibility: visible;
  width: rem(20);

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  @keyframes loading-spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

// Accessibility

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: rem(1);
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: rem(1);
}


//------------------------------------------------------------------------------
// HTML / BODY

html {
  min-height: 100%;

  &.no-scroll {
    height: 100%;

    body {
      height: 100%;
      overflow: hidden;
    }
  }
}

body {
  //background: linear-gradient($body-color rem(130), $content-color-dark) fixed;
  background: $body-color;
  margin: 0;
  min-height: 100%;
  min-width: rem($container-min-width);

  &.calendar {
    @media (min-width: $break-lg-em) {
      overflow: hidden;
    }
  }
}


//------------------------------------------------------------------------------
// SECTIONS

.header {
  background: $body-color;
  height: rem(55);

  @media (min-width: $break-sm-em) {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 500;
  }

  @media (min-width: $break-xl-em) {
    height: rem(70);
  }

  &-container {
    border-bottom: rem(1) solid transparentize($text-color, 0.6);

    @media (min-width: $break-md-em) {
      border: 0;
    }
  }
}

.main {
  display: block;

  .calendar & {
    @media (min-width: $break-md-em) {
      bottom: 0;
      display: flex;
      left: 0;
      position: absolute;
      right: 0;
      top: rem(55);
    }

    @media (min-width: $break-xl-em) {
      top: rem(70);
    }
  }

  &-login {
    overflow-y: auto;
  }
}


//------------------------------------------------------------------------------
// NAVIGATION

.logo {
  display: block;
  margin-top: rem(11);
  user-select: none;

  @media (min-width: $break-lg-rem) {
    margin-top: rem(13);
  }
}

.main-navigation {
  display: flex;
  opacity: 1;
  user-select: none;
  visibility: visible;

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  & > ul {
    display: flex;
    list-style: none;
    margin: 0 0 0 auto;
    padding: 0;

    @media (min-width: $break-md-rem) {
      margin-right: 0;
    }

    .main-navigation-item {
      padding: rem(8) rem(4);
      position: relative;
      text-transform: uppercase;

      @media (min-width: $break-lg-rem) {
        padding-left: rem(7);
        padding-right: rem(7);
      }

      &.hover {
        ul {
          display: block;
          list-style: none;
          margin: 0;
          padding: 0;
        }

        a {
          background: $text-color;
          color: $text-color-dark;
        }
      }

      &.first {
        padding-left: 0;
      }

      &.last {
        padding-right: 0;
      }

      &.hidden {
        display: none;
      }

      a {
        display: block;
        font-weight: $font-weight-normal;
        line-height: rem(34);
        padding: rem(2) rem(10);
        position: relative;
        white-space: nowrap;

        &.active {
          font-weight: $font-weight-bold;

          &:before {
            border-bottom: rem(2) solid $text-color;
            bottom: rem(4);
            content: "";
            left: rem(5);
            position: absolute;
            right: rem(5);
          }
        }

        &:focus,
        &:hover {
          background: $text-color;
          color: $link-focus-color;
        }
      }

      ul {
        background: $text-color;
        display: none;
        position: absolute;
        right: rem(4);
        z-index: 100;
      }

      .main-navigation-item {
        padding: 0;
        text-align: right;

        &:before {
          border-bottom: rem(1) solid $text-color-dark;
          bottom: 0;
          content: "";
          left: rem(10);
          position: absolute;
          right: rem(10);
          z-index: 10;
        }

        &:last-child {
          &:before {
            display: none;
          }
        }
      }
    }
  }

  &-login-link {
    display: block;
    fill: $text-color;
    height: rem(54);
    padding: rem(10) 0;
    width: rem(44);

    &-icon {
      border-radius: 50%;
      height: rem(34);
      margin-left: auto;
      padding: 0 rem(4);
      width: rem(34);
    }

    svg {
      height: rem(34);
      position: relative;
      right: rem(-1);
      width: rem(24);
    }

    &:focus,
    &:hover {
      background: none;
      fill: $link-focus-color;

      .main-navigation-login-link-icon {
        background: $text-color;
      }
    }
  }
}


//------------------------------------------------------------------------------
// LISTS

.reset-list {
  list-style: none;
  margin: 0;
  padding: 0;
}


//------------------------------------------------------------------------------
// CONTENT

.content {
  &-100 {
    @media (min-width: $break-md-em) {
      flex: 1 1 auto;
    }
  }

  &-bg-dark {
    background: linear-gradient($body-color, $content-color-dark);
  }

  &-bg-light {
    background: $content-color-light;
  }
}


//------------------------------------------------------------------------------
// LINKS

a {
  color: $link-color;
  outline: 0;
  text-decoration: none;

  &:focus,
  &:hover {
    text-decoration: none;
  }
}


//------------------------------------------------------------------------------
// LINKS

.preloader {
  height: rem(80);
  left: 50%;
  margin-left: rem(-40);
  margin-top: rem(-40);
  position: absolute;
  top: 50%;
  width: rem(80);

  div {
    animation: spin 2s ease infinite;
    border-radius: 50%;
    height: 40%;
    position: absolute;
    width: 40%;

    &:first-child {
      animation-delay: -1.5s;
      background: transparentize($content-color, 0.25);
    }

    &:nth-child(2) {
      animation-delay: -1s;
      background: transparentize($content-color, 0.5);
    }

    &:nth-child(3) {
      animation-delay: -0.5s;
      background: transparentize($content-color, 0.75);
    }

    &:last-child {
      background: $content-color;
    }
  }

  @keyframes spin {
    0%,
    100% {
      transform: translate(0);
    }

    25% {
      transform: translate(160%);
    }

    50% {
      transform: translate(160%, 160%);
    }

    75% {
      transform: translate(0, 160%);
    }
  }
}


//------------------------------------------------------------------------------
// DIALOG

.dialog {
  background: $dialog-bg-color;
  border-radius: rem(8);
  box-shadow: 0 0 rem(60) transparentize($body-color, 0.75);
  flex: 0 1 rem(880);
  margin: auto;
  max-width: rem(880);
  opacity: 0;
  position: relative;
  transform: translateY(rem(-20));
  transition:
    200ms opacity,
    450ms transform,
    200ms visibility;
  visibility: hidden;
  z-index: 2000;

  &-sm {
    max-width: rem(600);
  }

  &-md {
    max-width: rem(730);
  }

  &-button {
    background: $content-color;
    border: rem(2) solid transparent;
    border-radius: rem(4);
    display: inline-block;
    height: rem(34);
    line-height: rem(30);
    padding: 0 rem(18);
    text-transform: uppercase;
    transition:
      250ms background,
      250ms border;
    user-select: none;

    &:focus {
      border-color: $text-color;
    }

    &:hover {
      background: lighten($content-color, 10%);
    }
  }

  &-overlay {
    -webkit-backdrop-filter: blur(5px);
    background: transparentize($dialog-wrapper-bg-color, 0.15);
    bottom: 0;
    cursor: pointer;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition:
      450ms opacity,
      450ms visibility;
    visibility: hidden;
    z-index: 1000;
  }

  &-wrapper {
    align-items: center;
    bottom: 0;
    display: flex;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition:
      600ms opacity,
      600ms visibility;
    visibility: hidden;
    z-index: 1000;

    &.show {
      opacity: 1;
      transition:
        450ms opacity,
        450ms visibility;
      visibility: visible;

      .dialog {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
      }

      .dialog-overlay {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &-scroll {
    align-items: center;
    bottom: 0;
    display: flex;
    left: 0;
    min-width: rem($container-min-width);
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    padding: rem($col-xs-gutter * 2);
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1010;

    @media (min-width: $break-md-rem) {
      padding: rem($col-md-gutter * 2);
    }
  }

  &-header {
    align-items: center;
    display: flex;
    padding: rem(5) rem(5) rem(5) 0;

    @media (min-width: $break-sm-rem) {
      padding: rem(5) rem(44) rem(5) 0;
    }

    @media (min-width: $break-md-rem) {
      padding: rem(5) rem(49) rem(5) rem(5);
    }
  }

  &-close {
    display: block;
    fill: $text-color;
    height: rem(44);
    padding: rem(10);
    width: rem(44);

    &-button {
      border-radius: 50%;
      height: rem(24);
      width: rem(24);
    }

    svg {
      height: rem(24);
      width: rem(24);
    }

    &:focus,
    &:hover {
      fill: $text-color-dark;

      .dialog-close-button {
        background: $text-color;
      }
    }
  }

  &-title {
    @extend .dialog-heading-font;
    margin: 0;
    user-select: none;
  }

  &-section {
    border-top: rem(3) solid $dialog-section-border-color;
    padding: rem(10) rem($col-xs-gutter * 2) 0;

    @media (min-width: $break-md-rem) {
      padding: rem(10) rem($col-md-gutter * 2) 0;
    }

    .title {
      @extend .dialog-heading-font;
      color: $text-color;
      margin: 0 0 rem(5);
      user-select: none;
    }

    .sub-title {
      @extend .h2;
      margin-top: 0;
      user-select: none;
    }
  }

  &-footer {
    border-top: rem(3) solid $dialog-section-border-color;
    padding: rem(11) rem($col-xs-gutter * 2);

    @media (min-width: $break-md-rem) {
      padding: rem(15) rem($col-md-gutter * 2);
    }
  }
}


//------------------------------------------------------------------------------
// FORMS

.form {

  // Fieldset / Legend

  &-legend {
    @extend .dialog-heading-font;
    color: $text-color;
    user-select: none;
  }

  &-fieldset-wrapper {
    padding-top: rem(5);
  }

  // Inputs, Select, Label, etc.

  &-item {
    display: flex;
    flex-direction: column;
    margin-bottom: rem(4);

    &.hidden {
      display: none;
    }

    &-no-label {
      margin-bottom: rem(10);

      .form-error-message {
        margin-top: rem(10);
      }
    }
  }

  &-label {
    @extend .special-font;
    display: flex;
    margin: rem(6) rem(3) rem(3) 0;
    min-height: rem(20);
    order: 1;
  }

  &-field-input {
    background: transparentize($text-color, 0.9);
    border: 0;
    border-bottom: rem(1) solid transparentize($text-color, 0.6);
    border-radius: rem(4) rem(4) 0 0;
    color: $form-input-text-color;
    font-size: rem(14);
    height: rem(28);
    outline: 0;
    padding: rem(5);
    width: 100%;

    &:focus {
      background: transparentize($text-color, 0.85);
      border-color: transparentize($text-color, 0.2);
    }

    &[disabled] {
      cursor: default;
    }

    &.invalid {
      border-color: $warning-color;
    }

    &[type="date"],
    &[list] {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23FFFFFF' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
      background-position: right rem(10) center;
      background-repeat: no-repeat;
      background-size: rem(8) rem(10);
    }
  }

  &-field-select {
    appearance: none;
    background-color: transparent;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23FFFFFF' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
    background-position: right rem(5) center;
    background-repeat: no-repeat;
    background-size: rem(8) rem(10);
    border: 0;
    color: $form-select-text-color;
    font-size: rem(14);
    height: rem(20);
    outline: 0;
    padding-right: rem(20);
    position: relative;
    width: 100%;
    z-index: 10;

    &-wrapper {
      display: flex;
      padding: rem(4) rem(5);
      position: relative;
    }

    &-overlay {
      background: transparentize($text-color, 0.9);
      border-bottom: rem(1) solid transparentize($text-color, 0.6);
      border-radius: rem(4) rem(4) 0 0;
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    &[readonly] {
      background: none;
      pointer-events: none;

      & + .form-field-select-overlay {
        cursor: not-allowed;
      }
    }

    &[disabled] {
      cursor: default;
    }

    &:focus {
      & + .form-field-select-overlay {
        background: transparentize($text-color, 0.85);
        border-color: transparentize($text-color, 0.2);
      }
    }

    &.invalid {
      & + .form-field-select-overlay {
        border-color: $warning-color;
      }
    }

    optgroup {
      color: $form-select-optgroup-color;
      font-weight: $font-weight-bold;
    }

    option {
      color: $form-select-option-color;

      &[disabled] {
        background: $form-select-option-disabled-bg-color;
        color: $form-select-option-disabled-color;
      }
    }
  }

  &-field-checkbox {
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;

    &-wrapper {
      margin-bottom: rem(1);
      //margin-top: rem(4);


      //@media (min-width: $break-sm-rem) {
      //  column-count: 2;
      //  column-gap: rem(30);
      //}
    }

    &-label {
      @extend .special-font;
      break-inside: avoid;
      display: inline-flex;
      margin: 0 auto rem(9) 0;
      padding: rem(1) rem(20) 0 0;
      position: relative;
    }

    &-title {
      margin-top: rem(2);
    }

    &-indicator {
      border: rem(2) solid transparentize($text-color, 0.5);
      display: block;
      flex: 0 0 rem(20);
      height: rem(20);
      margin-right: rem(10);
      max-width: rem(20);
      position: relative;
      width: rem(20);
    }

    &.invalid ~ .form-field-checkbox-indicator {
      border-color: $warning-color;
    }

    &:focus ~ .form-field-checkbox-indicator {
      border-color: $text-color;
    }

    &:checked ~ .form-field-checkbox-indicator {
      &:after {
        border: solid $text-color;
        border-width: 0 rem(3) rem(3) 0;
        content: "";
        height: rem(9);
        left: rem(3);
        margin: rem(2);
        position: absolute;
        top: rem(-1);
        transform: rotate(45deg);
        width: rem(3);
      }
    }
  }

  &-field-radio {
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;

    &-wrapper {
      margin-bottom: rem(7);
      margin-top: rem(4);
    }

    &-label {
      @extend .special-font;
      break-inside: avoid;
      display: flex;
      margin-bottom: rem(7);
      padding-right: rem(20);
      position: relative;
    }

    &-indicator {
      border: rem(2) solid transparentize($text-color, 0.5);
      border-radius: 50%;
      display: block;
      flex: 0 0 rem(18);
      height: rem(18);
      margin-right: rem(10);
      max-width: rem(18);
      position: relative;
      width: rem(18);
    }

    &.invalid ~ .form-field-radio-indicator {
      border-color: $warning-color;
    }

    &:focus ~ .form-field-radio-indicator {
      border-color: $text-color;
    }

    &:checked ~ .form-field-radio-indicator {
      &:after {
        background: $text-color;
        border-radius: 50%;
        content: "";
        height: rem(10);
        left: 0;
        margin: rem(2);
        position: absolute;
        top: 0;
        width: rem(10);
      }
    }
  }

  // Validation

  &-field-required {
    text-decoration: none;
  }

  &-field-warning {
    display: none;
    height: rem(20);
    margin-left: auto;
    order: 1;
    width: rem(20);

    &.show {
      display: inline-block;
    }

    svg {
      fill: $warning-color;
      height: rem(20);
      width: rem(20);
    }
  }

  &-errornote {
    background: $text-color;
    border-radius: rem(4);
    color: $warning-color;
    font-weight: $font-weight-bold;
    margin-bottom: rem(22);
    padding: rem(7) rem(10);
    user-select: none;
  }

  &-error-message {
    background: $text-color;
    border-radius: rem(4);
    color: $warning-color;
    display: none;
    font-weight: $font-weight-bold;
    margin-bottom: rem(6);
    margin-right: auto;
    order: 1;
    padding: rem(4) rem(5);
    position: relative;
    user-select: none;

    &:after {
      border-bottom-color: $text-color;
      border-left-color: transparent;
      border-right-color: transparent;
      border-style: solid;
      border-top-color: transparent;
      border-width: rem(4);
      content: "";
      height: rem(0);
      left: rem(15);
      position: absolute;
      top: rem(-8);
      width: rem(0);
    }

    &.show {
      display: inline-block;
    }
  }

  .was-validated {
    :invalid {
      border-color: $warning-color;
    }
  }

  // Employee photo

  &-add-employee-label {
    background: $text-color;
    border: rem(2) solid transparent;
    border-radius: 50%;
    color: $text-color-dark;
    display: flex;
    flex-direction: column-reverse;
    height: rem(80);
    justify-content: center;
    line-height: 1.2;
    margin: 0 auto;
    overflow: hidden;
    padding: 0 rem(0);
    position: relative;
    text-align: center;
    transition:
      250ms background,
      250ms border;
    width: rem(80);

    img {
      height: rem(76);
      position: absolute;
      width: rem(76);
    }

    .add-image-icon {
      fill: transparentize($text-color-dark, 0.5);
      height: rem(48);
      width: rem(76);

      svg {
        height: rem(48);
        width: rem(76);
      }
    }
  }

  &-add-employee-preview-photo {
    display: none;

    &.show {
      display: block;
    }
  }

  &-add-employee-photo {
    margin-bottom: rem(14);
    position: relative;
    text-align: center;

    [type="file"] {
      opacity: 0;
      position: absolute;
      width: rem(10);

      &:focus ~ .form-add-employee-label {
        background: $button-focus-bg-color;
        border-color: $button-focus-border-color;
        color: $button-color;
      }

      &.invalid ~ .form-add-employee-label {
        border-color: $warning-color;
      }
    }

    .form-field-warning {
      margin: 0 auto;
    }

    .form-error-message {
      margin-top: rem(10);
      max-width: rem(320);

      &:after {
        left: 50%;
        margin-left: rem(-4);
      }
    }
  }

  // Button

  &-button {
    background: $button-bg-color;
    border: rem(2) solid transparent;
    border-radius: rem(4);
    box-shadow:
      0 0 0 rem(2) transparent,
      0 rem(3) rem(10) transparentize($body-color, 0.85);
    color: $button-color;
    display: block;
    height: rem(38);
    margin-left: auto;
    outline: 0;
    padding: 0 rem(10);
    text-transform: uppercase;
    transition:
      250ms background,
      250ms border,
      75ms box-shadow;

    @media (min-width: $break-sm-rem) {
      padding-left: rem(18);
      padding-right: rem(18);
    }

    &[disabled] {
      cursor: default;
    }

    &:not([disabled]) {
      &:focus {
        background: $button-focus-bg-color;
        border-color: $button-focus-border-color;
        box-shadow: 0 rem(3) rem(10) transparentize($body-color, 0.55);
      }

      &:hover {
        background: $button-hover-bg-color;
        border-color: $button-hover-border-color;
        box-shadow: 0 rem(3) rem(10) transparentize($body-color, 0.55);
      }
    }

    &-cancel {
      background: transparent;
      border: 0;
      color: $text-color;
      display: block;
      line-height: rem(38);
      padding: 0 rem(10);
      text-transform: uppercase;
      user-select: none;

      &:focus,
      &:hover {
        background: $text-color;
        border-radius: rem(4);
        box-shadow:
          0 0 0 rem(2) transparent,
          0 rem(3) rem(10) transparentize($body-color, 0.85);
        color: $text-color-dark;
      }
    }
  }
}


//------------------------------------------------------------------------------
// FORMSETS

.formset {
  &-label {
    color: $body-color;
    margin-left: rem(8);
    z-index: 10;

    &.hidden {
      display: none;
    }
  }

  &-header {
    align-items: center;
    background: $content-color;
    border-bottom: rem(1) solid $text-color;
    border-radius: rem(4) rem(4) 0 0;
    display: flex;
    line-height: rem(33);
    padding: 0 rem($col-xs-gutter * 2);

    @media (min-width: $break-lg-rem) {
      padding: 0 rem($col-md-gutter * 2);
    }

    .title {
      @extend .section-heading-font;
      flex: 1 0 auto;
      line-height: rem(33);
      margin: 0;
      user-select: none;
    }

    .dialog & {
      background: none;
      border-bottom: 0;
      border-top: rem(3) solid $dialog-section-border-color;
      line-height: initial;
      padding-left: rem($col-xs-gutter * 2);
      padding-right: rem($col-xs-gutter * 2);

      @media (min-width: $break-md-rem) {
        padding-left: rem($col-md-gutter * 2);
        padding-right: rem($col-md-gutter * 2);
      }

      .title {
        @extend .dialog-heading-font;
        color: $text-color;
        margin: 0;
        user-select: none;
      }
    }
  }

  &-add {
    background: none;
    border: 0;
    fill: $text-color;
    height: rem(33);
    outline: 0;
    padding: 0 rem(7);
    position: relative;
    right: rem(-8);
    width: rem(44);

    .dialog & {
      height: rem(44);
      right: rem(-7);
    }

    &-button {
      border-radius: 50%;
      display: block;
      height: rem(24);
      margin: 0 auto;
      width: rem(24);

      .dialog & {
        height: rem(30);
        width: rem(30);
      }
    }

    svg {
      height: rem(24);
      width: rem(24);

      .dialog & {
        height: rem(30);
        width: rem(30);
      }
    }

    &:focus,
    &:hover {
      fill: $text-color-dark;

      .formset-add-button {
        background: $text-color;
      }
    }
  }

  &-items {
    background: $content-color-light;
    padding: rem(15) rem($col-xs-gutter * 2) 0;

    @media (min-width: $break-lg-rem) {
      padding: rem(15) rem($col-md-gutter * 2) rem(2);
    }

    .dialog & {
      padding: 0 rem($col-xs-gutter * 2);

      @media (min-width: $break-md-rem) {
        padding-left: rem($col-md-gutter * 2);
        padding-right: rem($col-md-gutter * 2);
      }
    }
  }

  &-item {
    &.hidden {
      display: none;
    }

    .delete {
      background: none;
      border: 0;
      fill: $text-color;
      height: rem(44);
      outline: 0;
      padding: rem(10) rem(5);
      position: relative;
      right: rem(-4);
      top: rem(-7);
      width: rem(34);

      &:focus,
      &:hover {
        fill: $text-color-dark;

        .delete-button {
          background: $text-color;
        }
      }

      &-button {
        border-radius: 50%;
        display: block;
        height: rem(20);
        margin: 0 auto;
        width: rem(20);
      }

      svg {
        height: rem(20);
        width: rem(20);
      }
    }

    &-wrapper {
      border-top: rem(2) solid transparentize($text-color, 0.8);
      padding-top: rem(10);
    }
  }
}


//------------------------------------------------------------------------------
// LOGIN / PASSWORD RESET

.login {
  &-container {
    align-items: center;
    display: flex;
    height: 100vh;
  }

  &-header {
    padding: rem(15) rem(15);
    text-align: center;

    @media (min-width: $break-sm-rem) {
      padding: rem(15) rem(30);
    }
  }

  &-form {
    &-section {
      padding-bottom: rem(10);
      padding-left: rem(15);
      padding-right: rem(15);
      padding-top: rem(5);

      @media (min-width: $break-sm-rem) {
        padding-left: rem(30);
        padding-right: rem(30);
      }
    }
  }

  &-wrapper {
    background: $dialog-bg-color;
    border-radius: rem(8);
    box-shadow: 0 0 rem(60) transparentize($body-color, 0.75);
    margin: 0 auto;
    width: rem(300);

    @media (min-width: $break-sm-rem) {
      width: rem(400);
    }

    @media (min-width: $break-md-rem) {
      width: rem(500);
    }
  }

  &-footer {
    border-top: rem(3) solid $dialog-section-border-color;
    padding: rem(15);

    @media (min-width: $break-sm-rem) {
      padding: rem(20) rem(30);
    }
  }

  &-link {
    align-items: center;
    display: flex;
    fill: $text-color;
    height: rem(44);
    left: rem(-10);
    padding: rem(10);
    position: relative;

    &-icon {
      border-radius: 50%;
      height: rem(24);
      margin-right: rem(10);
      width: rem(24);
    }

    svg {
      height: rem(24);
      width: rem(24);
    }

    &:focus,
    &:hover {
      fill: $text-color-dark;

      .login-link-icon {
        background: $text-color;
      }
    }
  }
}


//------------------------------------------------------------------------------
// CALENDAR

.calendar {
  &-back {
    align-items: center;
    display: flex;
    fill: $text-color;
    height: rem(34);

    @media (min-width: $break-md-rem) {
      display: none;
    }

    &-button {
      border-radius: 50%;
      height: rem(24);
      margin-right: rem(10);
      width: rem(24);
    }

    svg {
      height: rem(24);
      width: rem(24);
    }

    &:focus,
    &:hover {
      fill: $text-color-dark;

      .calendar-back-button {
        background: $text-color;
      }
    }
  }

  &-overview {
    display: none;

    &.show {
      display: block;
    }

    @media (min-width: $break-sm-rem) {
      padding: rem(55) 0 rem(10);
    }

    @media (min-width: $break-md-rem) {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 0;
      width: rem(347);
    }

    @media (min-width: $break-lg-rem) {
      width: rem(400);
    }

    @media (min-width: $break-xl-rem) {
      width: rem(550);
    }

    &-header {
      margin: 0 rem($col-xs-gutter * 2);
      padding: rem(10) 0;

      @media (min-width: $break-sm-rem) {
        padding-bottom: 0;
      }

      @media (min-width: $break-md-rem) {
        margin: 0 rem($col-md-gutter * 2) rem(10);
        padding-top: 0;
      }

      @media (min-width: $break-xl-rem) {
        margin: 0 rem($col-xl-gutter * 2);
      }
    }

    @media (min-width: $break-md-rem) {
      &-main {
        bottom: 0;
        left: 0;
        overflow-y: auto;
        position: absolute;
        right: 0;
        top: rem(44);
      }
    }
  }

  // Form

  &-filter {
    background: $body-color;
    border-bottom: rem(1) solid transparentize($text-color, 0.6);
    margin: 0 rem($col-xs-gutter * 2);

    @media (min-width: $break-sm-rem) {
      position: sticky;
      top: 0;
      z-index: 10;
    }

    @media (min-width: $break-md-rem) {
      margin: 0 rem($col-md-gutter * 2) rem(10);
      position: sticky;
      top: 0;
      z-index: 10;
    }

    @media (min-width: $break-xl-rem) {
      margin: 0 rem($col-xl-gutter * 2) rem(10);
    }

    &-item {
      margin-bottom: rem(8);

      @media (min-width: $break-xl-rem) {
        margin-bottom: rem(10);
      }
    }

    &-wrapper {
      background: $body-color;
    }
  }

  &-field-select {
    appearance: none;
    background-color: transparent;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23FFFFFF' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
    background-position: right rem(5) center;
    background-repeat: no-repeat;
    background-size: rem(8) rem(10);
    border: 0;
    color: $form-select-text-color;
    font-size: rem(13);
    height: rem(20);
    outline: 0;
    padding-right: rem(20);
    position: relative;
    width: 100%;
    z-index: 10;

    @media (min-width: $break-lg-rem) {
      font-size: rem(14);
    }

    @media (min-width: $break-xl-rem) {
      font-size: rem(15);
    }

    &-wrapper {
      display: flex;
      margin-right: rem(4);
      padding: rem(4) rem(1) rem(4) rem(6);
      position: relative;

      @media (min-width: $break-sm-rem) {
        margin-right: rem(5);
      }

      @media (min-width: $break-lg-rem) {
        margin-right: rem(8);
        padding: rem(4) rem(5) rem(4) rem(10);
      }

      @media (min-width: $break-xl-rem) {
        margin-right: rem(9);
        padding: rem(6) rem(8) rem(6) rem(13);
      }
    }

    &-overlay {
      background: transparentize($text-color, 0.75);
      border-radius: rem(4);
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    &:focus {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23000000' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
      color: $text-color-dark;

      & + .calendar-field-select-overlay {
        background-color: $text-color;
      }
    }

    option {
      color: $form-select-option-color;
    }
  }

  &-button {
    background: transparentize($text-color, 0.75);
    border: 0;
    border-radius: rem(4);
    color: $text-color;
    font-size: rem(13);
    height: rem(28);
    outline: 0;
    padding: 0 rem(6);
    white-space: nowrap;

    @media (min-width: $break-lg-rem) {
      font-size: rem(14);
      padding: 0 rem(10);
    }

    @media (min-width: $break-xl-rem) {
      font-size: rem(15);
      height: rem(32);
      padding: 0 rem(13);
    }

    &:focus {
      background: $text-color;
      color: $text-color-dark;
    }
  }

  // Month calendar

  &-month {
    margin-top: rem(11);
    max-width: rem(600);
    position: relative;
    user-select: none;
    width: 100%;

    @media (min-width: $break-md-rem) {
      left: rem(-4);
    }

    &-wrapper {
      position: relative;
    }

    &-main {
      padding: rem(5) 0 rem(15);

      @media (min-width: $break-md-rem) {
        padding: 0 rem($col-md-gutter) rem(6) rem($col-md-gutter * 2);
      }

      @media (min-width: $break-xl-rem) {
        padding: 0 rem($col-xl-gutter * 2) rem(6);
      }
    }

    &-details {
      &-wrapper {
        opacity: 0;
        position: relative;
        visibility: hidden;
        z-index: 1;

        &.show {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  &-weekday {
    @extend .special-font;
    padding-bottom: rem(4);
    text-align: center;
  }

  &-day {
    padding: rem(4) 0;

    @media (min-width: $break-lg-rem) {
      padding: rem(8);
    }

    @media (min-width: $break-xl-rem) {
      padding-bottom: rem(8);
      padding-top: rem(8);
    }

    &-item {
      border: rem(2) solid transparent;
      border-radius: 50%;
      display: block;
      height: rem(30);
      margin: 0 auto;
      padding: rem(3) 0;
      position: relative;
      text-align: center;
      width: rem(30);

      @media (min-width: $break-xl-rem) {
        height: rem(38);
        padding: rem(7) 0;
        width: rem(38);
      }

      &-today {
        background: $text-color;
        border-color: $text-color;
        color: $text-color-dark;
      }

      &-event {
        background: $content-color;
        border-color: $content-color;
      }

      &.selected {
        background: $content-color;
        border-color: $text-color;
        color: $text-color;
      }

      &:focus {
        background: transparentize($content-color, 0.4);
        border-color: $text-color;
        color: $text-color;
      }
    }
  }

  // Details

  &-details {
    background: $body-color;
    bottom: 0;
    display: none;
    left: 0;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    padding: 0 rem($col-form-gutter * 2);
    position: absolute;
    right: 0;
    top: rem(120);
    z-index: 100;

    &.show {
      display: block;
    }

    @media (min-width: $break-md-rem) {
      display: block;
      padding: 0;
      top: 0;
    }

    &-header {
      border-bottom: rem(1) solid transparentize($text-color, 0.6);
      display: none;
      margin: 0 rem($col-xs-gutter * 2) rem(10);
      padding: rem(9) 0 rem(10);

      @media (min-width: $break-sm-rem) {
        margin-top: rem(55);
      }

      @media (min-width: $break-md-rem) {
        margin-top: 0;
      }

      &.show {
        display: block;
      }
    }

    &-wrapper {
      position: initial;

      @media (min-width: $break-md-rem) {
        position: relative;
      }
    }

    &-form {
      flex: 0 0 100%;
    }

    &-section {
      background: $content-color;
      display: flex;
      padding-bottom: rem(20);

      &-header {
        align-items: center;
        background: $content-color-light;
        display: flex;
        padding: rem(3) rem(25) rem(3) rem(40);
      }

      &-title {
        @extend .section-heading-font;
        flex: 1 0 auto;
        margin: 0;
        user-select: none;
      }

      &-main {
        padding: rem(25) rem(40) rem(15);
      }

      &-footer {
        padding: 0 rem(40) rem(15);
      }
    }
  }
}


//------------------------------------------------------------------------------
// CALENDAR: EVENT

.event {
  &-status {
    align-self: flex-start;
    display: block;
    flex: 0 0 rem(38);
    height: rem(34);
    padding: rem(8) rem(10);
    position: relative;
    top: rem(-1);

    .formset-item & {
      display: block;
      height: rem(20);
      margin-left: rem(10);
      margin-top: rem(5);
      padding: 0;
      top: 0;
      width: rem(20);
    }
  }

  &-icon {
    border: rem(1) solid transparent;
    border-radius: 50%;
    display: block;
    height: rem(20);
    position: relative;
    width: rem(20);

    .availability & {
      margin-left: rem(7);
    }

    &.hidden {
      display: none;
    }

    .calendar-day & {
      bottom: -24%;
      height: rem(16);
      position: absolute;
      right: -35%;
      width: rem(16);

      @media (min-width: $break-xl-rem) {
        bottom: -23%;
        height: rem(18);
        right: -23%;
        width: rem(18);
      }
    }

    svg {
      height: rem(20);
      left: rem(1);
      position: absolute;
      top: rem(-1);
      width: rem(16);

      .calendar-day & {
        height: rem(14);
        left: 0;
        top: 0;
        width: rem(14);

        @media (min-width: $break-xl-rem) {
          height: rem(16);
          width: rem(16);
        }
      }
    }

    &-warning {
      background: $warning-color;
      border-color: lighten($warning-color, 10%);
      fill: $text-color;
    }

    &-okay {
      background: $okay-color;
      border-color: lighten($okay-color, 10%);
      fill: $text-color;
    }

    &-info {
      background: $info-color;
      border-color: lighten($info-color, 20%);
      fill: darken($info-color, 35%);
    }

    &-done {
      background: $content-color;
      border-color: lighten($content-color, 20%);
      fill: $text-color;
    }
  }
}


//------------------------------------------------------------------------------
// CALENDAR: MONTH DETAILS

.month-details {
  user-select: none;

  &-header {
    @extend .section-heading-font;
    background: $content-color;
    line-height: rem(34);
    margin: 0;
    padding: 0 0 0 rem($col-xs-gutter * 2);

    @media (min-width: $break-xl-rem) {
      padding: rem(5) 0 rem(5) rem($col-md-gutter * 2);
    }

    &-wrapper {
      padding-left: rem(38);
      position: sticky;
      top: 0;
      z-index: 10;

      @media (min-width: $break-lg-rem) {
        padding-left: rem(60);
      }
    }
  }

  &-wrapper {
    opacity: 0;
    position: relative;
    transition: opacity 100ms;
    visibility: hidden;
    z-index: 10;

    &.show {
      opacity: 1;
      visibility: visible;
    }
  }

  &-content {
    list-style: none;
    padding-left: 0;
    position: relative;

    &-wrapper {
      background-color: $content-color-light;
      width: 100%;
    }

    &-item {
      border-bottom: rem(1) solid transparentize($text-color, 0.85);
      display: flex;

      &:hover {
        background-color: darken($content-color-light, 5%);
      }

      &.last {
        border: 0;
      }
    }

    &-time {
      flex: 1 0 100%;
      font-weight: $font-weight-bold;
      line-height: 1.2;
      padding: rem(8) 0 0 rem($col-xs-gutter * 2);

      @media (min-width: $break-sm-rem) {
        flex: 0 0 rem(100);
        padding-bottom: rem(8);
      }

      @media (min-width: $break-lg-rem) {
        flex: 0 0 rem(110);
      }

      @media (min-width: $break-xl-rem) {
        flex: 0 0 rem(130);
        padding-left: rem($col-md-gutter * 2);
        padding-right: rem($col-md-gutter);
      }
    }

    &-title {
      font-weight: $font-weight-bold;
      line-height: 1.2;
      padding: rem(8) rem($col-xs-gutter * 2) rem(8) 0;

      .sub-title {
        display: flex;
        font-family: $font-family-special;
        font-size: 90%;
        font-weight: $font-weight-normal;
        left: rem(-2);
        margin-top: rem(4);
        position: relative;

        &-icon {
          display: block;
          fill: $text-color;
          height: rem(16);
          margin-right: rem(3);
          width: rem(16);

          svg {
            height: rem(16);
            width: rem(16);
          }
        }
      }

      &-wrapper {
        display: flex;
        flex: 1 1 auto;
      }
    }

    &-link {
      display: flex;
      flex: 1 1 auto;
      flex-wrap: wrap;

      @media (min-width: $break-sm-rem) {
        flex-wrap: nowrap;
      }

      &:focus,
      &:hover {
        .calendar-month-details-content-title {
          text-decoration: underline;
        }
      }
    }

    &-delete {
      align-self: flex-start;
      display: block;
      fill: $text-color;
      flex: 0 0 rem(29);
      height: rem(38);
      padding: rem(8) rem(5) rem(8) 0;
      width: rem(29);

      @media (min-width: $break-md-rem) {
        flex: 0 0 rem(34);
        padding: rem(8) rem(10) rem(8) 0;
        width: rem(34);
      }

      @media (min-width: $break-xl-rem) {
        margin-right: rem(5);
      }

      &-button {
        height: rem(24);
        width: rem(24);
      }

      svg {
        height: rem(24);
        width: rem(24);
      }

      &:focus,
      &:hover {
        fill: $warning-color;
      }
    }
  }

  &-date {
    @extend .special-font;
    background: $content-color-dark;
    display: block;
    height: rem(54);
    padding-top: rem(8);
    position: sticky;
    text-align: center;
    text-transform: uppercase;
    top: 0;
    width: rem(38);
    z-index: 10;

    @media (min-width: $break-lg-rem) {
      width: rem(60);
    }

    @media (min-width: $break-xl-rem) {
      height: rem(55);
    }
  }

  &-item {
    border-top: rem(1) solid $text-color;
    display: flex;
    position: relative;

    .send-sms {
      display: block;
      fill: $text-color;
      flex: 0 0 rem(44);
      padding: rem(14) 0;

      &-button {
        height: rem(24);
        margin: 0 auto;
        width: rem(24);
      }

      svg {
        height: rem(24);
        width: rem(24);
      }

      &:focus,
      &:hover {
        fill: $content-color;
      }
    }

    &-today {
      background: $text-color;
      color: $text-color-dark;

      & + .send-sms {
        border-color: $text-color;
      }
    }
  }

  &-title {
    margin-bottom: 0;
    margin-top: 0;
    width: rem(38);

    @media (min-width: $break-lg-rem) {
      width: rem(62);
    }
  }
}


//------------------------------------------------------------------------------
// CALENDAR: EMPLOYEES AVAILABILITY

.availability {
  margin-bottom: rem(20);

  &-header,
  &-main {
    margin: 0 rem($col-xs-gutter * 2);

    @media (min-width: $break-md-rem) {
      margin: 0 rem($col-md-gutter * 2);
    }

    @media (min-width: $break-xl-rem) {
      margin-left: rem(40);
      margin-right: rem(40);
    }
  }

  &-title {
    font-family: $font-family-heading;
    font-weight: $font-weight-normal;
    letter-spacing: 0.05em;
    margin: rem(10) 0 rem(2);
    text-transform: uppercase;
    user-select: none;

    time {
      white-space: nowrap;
    }
  }

  &-list {
    margin-bottom: rem(5);

    &-title {
      background: $content-color-light;
      border-radius: rem(4) rem(4) 0 0;
      display: flex;
      margin: rem(10) 0 0;
      padding: rem(5) rem($col-xs-gutter * 2);
      user-select: none;

      .type {
        flex: 0 0 auto;
        margin-left: auto;
      }
    }

    &-item {
      border-bottom: rem(1) solid transparentize($text-color, 0.6);

      a,
      .no-filter {
        align-items: center;
        display: flex;
        padding: rem(5) rem($col-xs-gutter * 2);

        &.active {
          border-left: rem(4) solid transparentize($text-color, 0.6);
          font-weight: $font-weight-bold;
          padding-left: rem($col-xs-gutter * 2 - 4);
        }
      }

      .info {
        display: flex;
        margin-left: auto;
        padding-left: rem(7);
      }
    }
  }
}


//------------------------------------------------------------------------------
// EMPLOYEES

.employees {
  &-header {
    background: $body-color;
    border-bottom: rem(1) solid transparentize($text-color, 0.6);
    display: none;
    padding: rem(10) 0;

    &.show {
      display: block;
    }

    @media (min-width: $break-sm-em) {
      padding-top: rem(65);
      position: sticky;
      top: 0;
      z-index: 20;
    }

    @media (min-width: $break-md-em) {
      display: block;
      padding: rem(55) 0 rem(10);
    }

    @media (min-width: $break-xl-em) {
      padding-top: rem(70);
    }
  }

  &-lists {
    display: none;
    padding-bottom: rem(50);

    &.show {
      display: block;
    }

    &.sticky {
      @media (min-width: $break-md-rem) {
        position: sticky;
        top: rem(100);
      }

      @media (min-width: $break-xl-rem) {
        top: rem(115);
      }
    }

    @media (min-width: $break-md-rem) {
      display: block;
    }
  }

  &-list {
    &-wrapper {
      padding-top: rem(10);

      @media (min-width: $break-md-rem) {
        padding-top: rem(15);
      }
    }

    &-title {
      background: $content-color-light;
      border-radius: rem(4) rem(4) 0 0;
      display: flex;
      margin: 0;
      padding: rem(5) rem($col-xs-gutter * 2);
      user-select: none;
    }

    &-item {
      align-items: center;
      border-bottom: rem(1) solid transparentize($text-color, 0.6);
      display: flex;

      .link {
        display: block;
        flex: 1 1 auto;
        padding: rem(8) rem($col-xs-gutter * 2);

        &:focus {
          background: transparentize($content-color, 0.7);
        }

        &[data-focus-method="mouse"],
        &[data-focus-method="touch"] {
          background: none;
        }
      }
    }
  }

  &-preloader {
    bottom: 0;
    display: none;
    left: 0;
    position: fixed;
    right: 0;
    top: rem(55);
    z-index: 2000;

    &.show {
      display: block;
    }

    @media (min-width: $break-md-rem) {
      bottom: rem(55);
      height: calc(100vh - 55rem / 16);
      position: relative;
    }
  }
}

.employee {
  &-back {
    display: block;
    fill: $text-color;
    height: rem(34);
    padding: rem(5) rem(10) rem(5) 0;
    width: rem(44);

    @media (min-width: $break-md-rem) {
      display: none;
    }

    &-button {
      border-radius: 50%;
      height: rem(24);
      width: rem(24);
    }

    svg {
      height: rem(24);
      width: rem(24);
    }

    &:focus,
    &:hover {
      fill: $text-color-dark;

      .employee-back-button {
        background: $text-color;
      }
    }
  }

  &-details,
  &-profil {
    padding-bottom: rem(40);


    @media (min-width: $break-md-rem) {
      display: block;
      padding-bottom: 0;
    }

    @media (min-width: $break-xl-rem) {
      padding-bottom: rem(20);
    }

    &-header {
      border-bottom: rem(1) solid transparentize($text-color, 0.6);
      margin-bottom: rem(10);

      @media (min-width: $break-sm-rem) {
        background: $body-color;
        position: sticky;
        top: 0;
        z-index: 20;
      }

      @media (min-width: $break-md-rem) {
        border: 0;
        margin: 0;
        position: initial;
      }

      @media (min-width: $break-xl-rem) {
        padding-top: rem(70);
      }
    }

    .section {
      margin-bottom: rem($col-xs-gutter * 2);

      @media (min-width: $break-md-rem) {
        margin-bottom: rem($col-md-gutter * 2);
      }

      &-header {
        background: $content-color;
        border-bottom: rem(1) solid $text-color;
        border-radius: rem(4) rem(4) 0 0;
        padding: 0 rem($col-xs-gutter * 2);

        @media (min-width: $break-lg-rem) {
          padding: 0 rem($col-md-gutter * 2);
        }

        .title {
          @extend .section-heading-font;
          flex: 1 0 auto;
          line-height: rem(33);
          margin: 0;
          user-select: none;
        }
      }

      &-main {
        background: $content-color-light;
        padding: rem(15) rem($col-xs-gutter * 2) 0;

        @media (min-width: $break-lg-rem) {
          padding: rem(15) rem($col-md-gutter * 2) rem(2);
        }
      }

      &-footer {
        background: $content-color-light;
        border-top: rem(3) solid $dialog-section-border-color;
        padding: rem(11) rem($col-xs-gutter * 2);

        @media (min-width: $break-lg-rem) {
          padding: rem(13) rem($col-md-gutter * 2);
        }
      }
    }
  }

  &-details {
    display: none;

    &.show {
      display: block;
    }

    @media (min-width: $break-md-rem) {
      display: block;
    }

    &-header {
      padding: rem(9) 0 rem(10);

      @media (min-width: $break-sm-rem) {
        padding-top: rem(64);
      }

      @media (min-width: $break-md-rem) {
        padding-top: rem(55);
      }
    }
  }

  &-profil {
    &-header {
      padding: rem(17) 0 rem(18);

      @media (min-width: $break-sm-rem) {
        padding: rem(73) 0 rem(17);
      }

      @media (min-width: $break-md-rem) {
        padding: rem(55) 0 rem(10);
      }
    }
  }

  &-initials {
    background: $blank-color;
    border: rem(1) solid $text-color;
    border-radius: 50%;
    color: $text-color-dark;
    flex: 0 0 rem(24);
    font-weight: $font-weight-bold;
    height: rem(24);
    line-height: rem(24);
    margin-right: rem(10);
    position: relative;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
    width: rem(24);

    small {
      display: block;
      line-height: rem(22);
    }

    img {
      border-radius: 50%;
      width: 100%;
    }

    &-wrapper {
      align-items: center;
      display: flex;

      .name {
        flex: 1 1 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    @media (min-width: $break-md-rem) {
      &-md {
        flex: 0 0 rem(28);
        height: rem(28);
        line-height: rem(28);
        margin-right: rem(15);
        width: rem(28);

        small {
          font-size: 110%;
          line-height: rem(26);
        }
      }
    }

    @media (min-width: $break-lg-rem) {
      &-lg {
        flex: 0 0 rem(32);
        height: rem(32);
        line-height: rem(32);
        width: rem(32);

        small {
          font-size: 110%;
          line-height: rem(30);
        }
      }
    }

    @media (min-width: $break-xl-rem) {
      &-xl {
        flex: 0 0 rem(36);
        height: rem(36);
        line-height: rem(36);
        width: rem(36);

        small {
          font-size: 110%;
          line-height: rem(34);
        }
      }
    }
  }

  &-register {
    display: block;
    fill: $text-color;
    height: rem(40);
    padding: rem(8) rem($col-xs-gutter * 2);
    width: rem(44);

    @media (min-width: $break-md-em) {
      height: rem(44);
      padding-bottom: rem(10);
      padding-top: rem(10);
    }

    @media (min-width: $break-lg-em) {
      height: rem(48);
      padding-bottom: rem(12);
      padding-top: rem(12);
    }

    @media (min-width: $break-xl-em) {
      height: rem(52);
      padding-bottom: rem(14);
      padding-top: rem(14);
    }

    svg {
      height: rem(24);
      width: rem(24);
    }

    &.done {
      fill: $okay-color;
    }

    &:focus {
      background: transparentize($content-color, 0.7);
    }

    &[data-focus-method="mouse"],
    &[data-focus-method="touch"] {
      background: none;
    }
  }
}


//------------------------------------------------------------------------------
// TIMESHEET

.timesheet {
  &-header {
    background: $body-color;

    @media (min-width: $break-sm-em) {
      left: 0;
      position: fixed;
      right: 0;
      top: rem(55);
      z-index: 20;
    }

    @media (min-width: $break-xl-em) {
      top: rem(70);
    }
  }

  &-container {
    border-bottom: rem(1) solid transparentize($text-color, 0.6);
    padding: rem(10) 0;

    @media (min-width: $break-md-em) {
      padding-top: 0;
    }
  }

  &-list {
    margin-bottom: rem(20);

    @media (min-width: $break-sm-em) {
      padding-top: rem(110);
    }

    @media (min-width: $break-md-em) {
      padding-top: rem(100);
    }

    @media (min-width: $break-xl-em) {
      padding-top: rem(125);
    }

    &-item {
      &.hidden {
        display: none;
      }

      @media (min-width: $break-xl-em) {
        align-items: flex-start;
        display: flex;
      }
    }
  }

  &-footer {
    display: flex;
    margin-bottom: rem(50);

    @media (min-width: $break-md-em) {
      margin-bottom: rem(20);
    }

    @media (min-width: $break-xl-em) {
      margin-bottom: rem(40);
    }

    .dialog-button {
      margin: 0 auto;

      @media (min-width: $break-sm-em) {
        margin-right: 0;
      }
    }
  }

  &-employee-name {
    font-weight: $font-weight-bold;
    padding: rem(8) 0;

    @media (min-width: $break-xl-em) {
      background: none;
      border: 0;
      border-radius: 0;
      flex: 0 0 rem(270);
      padding: rem(8) 0;
    }
  }

  &-employee-assignments {
    margin-bottom: rem(5);
    min-height: rem(36);

    @media (min-width: $break-xl-em) {
      flex: 1 1 auto;
      margin-bottom: rem(10);
      min-height: rem(40);
    }

    .events {
      background: $content-color-light;
      border-bottom: rem(1) solid transparentize($text-color, 0.5);
      flex: 1 1 auto;

      &-wrapper {
        margin-bottom: rem(10);

        @media (min-width: $break-sm-em) {
          display: flex;
        }
      }

      &-date {
        background: $content-color-dark;
        border-radius: rem(4) rem(4) 0 0;
        font-weight: $font-weight-bold;
        margin: 0;
        padding: rem(6) rem($col-xs-gutter * 2);

        @media (min-width: $break-sm-em) {
          background: $content-color-dark;
          border-radius: 0;
          flex: 0 0 rem(110);
          margin: 0;
        }
      }
    }

    .event {
      &-list {
        list-style-type: none;
        margin: 0;
        padding: 0;

        &-item {
          @media (min-width: $break-md-em) {
            display: flex;
          }
        }
      }

      &-location {
        @extend .default-font;
        background: $content-color;
        border-top: rem(1) solid transparentize($text-color, 0.5);
        font-weight: $font-weight-bold;
        padding: rem(6) rem($col-xs-gutter * 2);

        @media (min-width: $break-sm-em) {
          flex: 1 1 auto;
        }

        &-wrapper {
          @media (min-width: $break-sm-em) {
            display: flex;
          }
        }
      }

      &-detail {
        margin: 0;
        padding: rem(6) rem($col-xs-gutter * 2);

        @media (min-width: $break-md-em) {
          display: flex;
          flex: 1 1 auto;
        }

        &-wrapper {
          border-top: rem(1) solid transparentize($text-color, 0.5);
          flex: 1 1 auto;

          @media (min-width: $break-lg-em) {
            display: flex;
          }
        }
      }

      &-time {
        display: block;
        font-weight: $font-weight-bold;

        @media (min-width: $break-md-em) {
          flex: 0 0 rem(105);
          padding-right: rem($col-xs-gutter * 2);
        }
      }

      &-date {
        @media (min-width: $break-sm-em) {
          display: none;
        }
      }

      &-summary {
        display: block;
        font-weight: $font-weight-bold;

        @media (min-width: $break-md-em) {
          flex: 0 1 auto;
        }
      }

      &-guardian-hours {
        background: $content-color;
        margin: 0 0 0 auto;
        padding: 0 rem($col-xs-gutter * 2);

        @media (min-width: $break-sm-em) {
          border-top: rem(1) solid transparentize($text-color, 0.5);
          flex: 0 0 rem(220);
          margin-left: 0;
          padding-left: 0;
        }

        &-empty {
          display: none;

          @media (min-width: $break-md-em) {
            display: flex;
          }
        }

        label {
          display: flex;
          margin: 0 0 0 auto;
          max-width: rem(210);
          user-select: all;
        }

        input {
          @extend .default-font;
          background: transparent;
          border: 0;
          color: $text-color;
          font-weight: $font-weight-bold;
          height: rem(18);
          text-align: right;
          width: rem(45);

          &:focus {
            outline: 0;
          }
        }

        .title {
          border-left: rem(1) solid transparentize($text-color, 0.5);
          flex: 0 1 rem(105);
          margin-left: auto;
          padding: rem(6) 0 rem(6) rem($col-xs-gutter * 2);

          @media (min-width: $break-sm-em) {
            flex: 0 1 rem(142);
          }

          @media (min-width: $break-md-em) {
            margin-left: 0;
            padding-right: 0;
          }
        }

        .hours {
          font-weight: $font-weight-bold;
          margin-left: auto;
          padding: rem(6) 0;
        }
      }

      &-durations {
        background: transparentize($content-color, 0.7);
        display: flex;
        padding-left: rem($col-xs-gutter * 2);

        @media (min-width: $break-md-em) {
          border-left: rem(1) solid transparentize($text-color, 0.5);
          flex: 0 0 rem(220);
          padding: 0;
        }

        &-empty {
          display: none;

          @media (min-width: $break-md-em) {
            display: flex;
          }
        }

        &-list {
          flex: 0 0 rem(197);
          list-style: none;
          margin: 0 0 0 auto;
          padding: 0;

          @media (min-width: $break-sm-em) {
            flex: 0 0 rem(214);
          }

          @media (min-width: $break-md-em) {
            flex: 1 1 auto;
          }

          li {
            display: flex;
          }
        }

        .title {
          border-left: rem(1) solid transparentize($text-color, 0.5);
          flex: 0 1 rem(120);
          margin-left: auto;
          padding: rem(6) 0 rem(6) rem($col-xs-gutter * 2);

          @media (min-width: $break-sm-em) {
            flex: 0 1 rem(158);
          }

          @media (min-width: $break-md-em) {
            border-left: 0;
            border-top: rem(1) solid transparentize($text-color, 0.5);
            margin-left: 0;
          }
        }

        .hours {
          flex: 1 1 rem(40);
          font-weight: $font-weight-bold;
          padding: rem(6) rem($col-xs-gutter * 2) rem(6) 0;
          text-align: right;

          @media (min-width: $break-md-em) {
            border-top: rem(1) solid transparentize($text-color, 0.5);
          }
        }
      }

      &-attend {
        display: flex;
        margin: 0;
        padding: rem(2) rem($col-xs-gutter * 2) rem(8);

        @media (min-width: $break-lg-em) {
          flex: 0 0 auto;
          padding-bottom: 0;
          padding-top: rem(6);
        }

        .event-icon {
          margin-right: rem(10);
        }
      }
    }

    .employee-summary {
      border-top: rem(2) solid $text-color;
      display: flex;

      .funeral-count {
        flex: 1 0 rem(110);
        padding: rem(6) 0 rem(6) rem($col-xs-gutter * 2);

        ul {
          display: flex;
          flex-wrap: wrap;
          list-style: none;
          margin: 0;
          padding: 0;
        }

        li {
          margin-right: rem($col-xs-gutter * 3);
          white-space: nowrap;
        }
      }

      .title {
        margin-right: rem(10);
      }

      .month-hours {
        border-left: rem(1) solid $text-color;
        display: flex;
        flex-wrap: wrap;
        margin-left: auto;
        padding: rem(6) rem($col-xs-gutter * 2);

        @media (min-width: $break-sm-em) {
          flex: 0 0 rem(214);
          flex-wrap: nowrap;
        }

        @media (min-width: $break-md-em) {
          flex: 0 0 rem(220);
        }

        .hours {
          flex: 0 0 rem(40);
          font-weight: $font-weight-bold;
          text-align: right;

          @media (min-width: $break-sm-em) {
            margin-left: auto;
          }
        }
      }
    }
  }

  &-search {
    appearance: textfield;
    background-color: transparentize($text-color, 0.8);
    background-image: url("../img/magnify.svg");
    background-position: left rem(5) center;
    background-repeat: no-repeat;
    background-size: rem(22) rem(22);
    border: 0;
    border-radius: rem(4);
    color: $form-input-text-color;
    font-size: rem(14);
    height: rem(34);
    outline: 0;
    padding: rem(5) rem(5) rem(5) rem(35);
    width: rem(170);

    @media (min-width: $break-sm-em) {
      width: rem(190);
    }

    &:focus {
      background-color: transparentize($text-color, 0.6);
    }

    &::-webkit-input-placeholder {
      color: $text-color;
    }
  }
}



