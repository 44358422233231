//------------------------------------------------------------------------------
// COLORS

$body-color: #000;
$brand-color: #009797;
$warning-color: #BF0D01;
$okay-color: #2C830D;
$info-color: #F2D100;

$text-color: #FFFEFF;
$text-color-disabled: transparentize($text-color, 0.6);
$text-color-dark: #000;

$link-color: #FFFEFF;
$link-focus-color: #1B53DA;

$content-color-dark: #081235;
$content-color-light: #122C76;
$content-color: #1B53DA;

$blank-color: #D8D7DC;
$blank-text-color: #6B7BB6;

// Dialog

$dialog-wrapper-bg-color: #061333;
$dialog-bg-color: #122C76;
$dialog-section-border-color: #163588;

// Input

$form-input-bg-color: #122C76;
$form-input-border-color: #1D46B4;
$form-input-focus-bg-color: transparentize(#FFF, 0.9);
$form-input-focus-border-color: transparentize(#FFF, 0.5);
$form-input-text-color: #FFFEFF;

// Select

$form-select-optgroup-color: #000;
$form-select-option-color: #000;
$form-select-option-disabled-bg-color: #EDEDED;
$form-select-option-disabled-color: #808080;
$form-select-text-color: #FFFEFF;

// Button

$button-color: #FFFEFF;
$button-bg-color: #1B53DA;
$button-focus-bg-color: darken($button-bg-color, 5%);
$button-focus-border-color: #FFFEFF;
$button-hover-bg-color: darken($button-bg-color, 5%);
$button-hover-border-color: #FFFEFF;


//------------------------------------------------------------------------------
// FONTS

$font-family: "Roboto", sans-serif;
$font-family-heading: "Patuaone", serif;
$font-family-special: "Sanche", serif;

$font-weight-normal: 400;
$font-weight-bold: 500;
$line-height: 1.4;


//------------------------------------------------------------------------------
// BREAKPOINTS

$break-sm: 534; // HUAWEI M3 BTV Size
$break-md: 768;
$break-lg: 992;
$break-xl: 1200;

$break-sm-em: em($break-sm);
$break-md-em: em($break-md);
$break-lg-em: em($break-lg);
$break-xl-em: em($break-xl);

$break-sm-rem: rem($break-sm);
$break-md-rem: rem($break-md);
$break-lg-rem: rem($break-lg);
$break-xl-rem: rem($break-xl);


//------------------------------------------------------------------------------
// GRID

$container-min-width: 320;

$col-form-gutter: 5;

$col-xs-gutter: 5;
$col-md-gutter: 10;
$col-xl-gutter: 20;
